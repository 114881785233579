import React, { useState, useEffect } from "react";
import interceptor from "./interceptor/interceptor";
import { ArkoseAdaptor } from "./adaptor/arkoseAdaptor";
import { EventDataType } from "./adaptor/baseAdaptor";
import { MiscUtil } from "./utils/miscUtils";
import * as Constants from "./constant/constants";
import Config from "./partnerConfig";
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

export interface IProtocolInput {
  env: string;
}

interface ThemeConfig {
  [key: string]: string;
}

// sample url from challenge handler: https://www.pay.microsoft.com/challengeui?challenge_session_id=adff3815-10a7-4134-8c54-74ece2b3f22d&challenge_id=565fdede-e0c7-4b57-9672-aecd0415bea5&service_type=Arkose&public_key=E3375B74-54CD-4C1F-AB17-4C0C5303C2DA &company_name=microsoft&blob=AQHS8eMYB77wpCW1hbqWEeTQacOXzHA9yZbvFtcQFmkh6twPw3BLUf+KrsV/tq2IGTs7KQxaVB+dt07cghk2HyiXkFEYT8DJNxGLOmsohON3sIx/uPWb6P8D64d0o/iGTxkHXHLkdettn9mNKQ/nqea7Cur+uxOOAojVwtGxUsNdLrWjEil7+4bdk6hZ62k9kZqQ2+IS6DiNWoR/XlOhZT2w
// local sample url: http://localhost:3000/challengeui?challenge_session_id=adff3815-10a7-4134-8c54-74ece2b3f22d&challenge_id=565fdede-e0c7-4b57-9672-aecd0415bea5&service_type=Arkose&public_key=E3375B74-54CD-4C1F-AB17-4C0C5303C2DA&company_name=microsoft&blob=AQHS8eMYB77wpCW1hbqWEeTQacOXzHA9yZbvFtcQFmkh6twPw3BLUf+KrsV/tq2IGTs7KQxaVB+dt07cghk2HyiXkFEYT8DJNxGLOmsohON3sIx/uPWb6P8D64d0o/iGTxkHXHLkdettn9mNKQ/nqea7Cur+uxOOAojVwtGxUsNdLrWjEil7+4bdk6hZ62k9kZqQ2+IS6DiNWoR/XlOhZT2w

const challengeManagerSubmit = (
  data: EventDataType,
  eventType: string,
  challengeProviderName: string,
  env: string,
  cid: string
) => {
  return interceptor.post(
    `https://${env}`,
    {
      session_id: data.sessionId,
      challenge_provider_name: challengeProviderName,
      challenge_details: {
        event_type: eventType,
        event_details: {
          session_token: data.token,
        },
      },
    },
    {
      headers: {
        "Accept-Language": "en-us",
        "Content-Type": "application/json",
        "x-ms-correlation-id": cid,
        Accept: "application/json",
        Blob: data.encryptedBlob,
        Challenge_provider_name: challengeProviderName,
        Challenge_session_id: data.sessionId,
      },
    }
  );
};

const parseThemeConfig = (config: string): [ThemeConfig, string] => {
  if (MiscUtil.isNullOrUndefinedOrEmptyString(config)) 
    return [{}, ""]

  let defaultTheme = "";
  const result: ThemeConfig = {};
  const themes: string[] = config.split("|");
  
  for (let i = 0; i < Math.floor(themes.length/2); i++) {
    const partnerThemeName = themes[i*2]
    const arkoseThemeName = themes[i*2+1]

    if (partnerThemeName === "default") {
      defaultTheme = arkoseThemeName
    }

    result[partnerThemeName] = arkoseThemeName
  }

  return [result, defaultTheme]
}

export const ChallengeProtocol = (props: IProtocolInput) => {
  const url = (window.location != window.parent.location)
      ? document.referrer
      : document.location.href;

  // differentiate http protocol when using localhost for local testing
  const hostUrl = MiscUtil.getHostName(url)
  const actualOrigin = hostUrl === "localhost:3000" ? "http://" + hostUrl : "https://" + hostUrl;

  const challengeProviderName = MiscUtil.getQueryStringValue(
    Constants.challengeProviderParamName
  );
  const envName = MiscUtil.getQueryStringValue(Constants.envName);
  const cid = MiscUtil.getQueryStringValue(Constants.correlationId);
  const themeConfig = MiscUtil.getQueryStringValue(Constants.themeConfig)
  const partnerName = MiscUtil.getQueryStringValue(Constants.partner).toLowerCase(); // both use lowercase for string matching.

  const [theme, setTheme] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const ec = document.getElementById("arkose-ec");
    if (ec) {
      // Scroll to the Arkose Iframe.
      const checkIframe = setInterval(() => {
        ec.scrollIntoView({ behavior: 'smooth', block: 'center' });
        clearInterval(checkIframe);
      }, 100);
    } 

    // if partner name is not whitelisted, then we use default theme.
    if (!(partnerName in Config.partnerOnboarding)) {
      return
    }

    // // need to verify if the target origin has been whitelisted for that partner before posting message.
    // if (Config.isWhitelisted(props.env, partnerName)) {
    //   // post "challengeiframeLoaded" message before rendering arkose challenge
    //   // restrict the target origin to only whitelisted domains
    //   top?.postMessage("challengeiFrameLoaded", actualOrigin)
    // }

    // allow broadcasting message to all domains to avoid whitelisting overhead during prod code freeze
    // TODO: restrict the domains in the future?
    top?.postMessage("challengeiFrameLoaded", actualOrigin)

    // start parsingthe theme config object from query param immediately.
    const [partnerThemeMapping, defaultTheme] = parseThemeConfig(themeConfig)

    // set the Arkose default theme if a default theme is provided from the theme_config
    setTheme(defaultTheme)

    function handleThemeMessage(evt: MessageEvent<any>) {
      // check the event origin against partner whitelist config object
      if (evt.origin !== actualOrigin) {
        return
      }

      // iterate through all the partner themes to find a mapping
      Object.entries(partnerThemeMapping).forEach(mapping => {
        const [partnerThemeName, arkoseThemeName] = mapping
        if (evt.data === partnerThemeName) {
          setTheme(arkoseThemeName)
          return
        }
      })

      // if no partner theme name matched, default theme will be used at this point
    }

    // register event listener for theme message from host
    window.addEventListener("message", handleThemeMessage)

    // remove the event listener for clean up
    return () => window.removeEventListener("message", handleThemeMessage)
  }, [])

  const onCompleted = (data: EventDataType) => {
    console.log("the oncompleted event data is ", data);

    if (
      MiscUtil.caseInsensitiveStringComparison(
        challengeProviderName,
        Constants.ArkoseServiceType
      )
    ) {
      challengeManagerSubmit(
        data,
        "completed",
        challengeProviderName,
        envName,
        cid
      )
        .then((res) => {
          console.log("submit completed event succeed: ", res.data);
  
          // need to verify if the target origin has been whitelisted for that partner before posting message.
          if (Config.isWhitelisted(props.env, partnerName)) {
            // post "challengeCompleted" message after Arkose challenge is completed
            // restrict the target origin to only whitelisted domains
            top?.postMessage("challengeCompleted", actualOrigin)
          }
        })
        .catch((err) => {
          console.error("submit completed event failed: ", err);
        });
    }
  };

  const onFailed = (data: EventDataType) => {
    console.log("the onfailed event data is ", data);

    if (
      MiscUtil.caseInsensitiveStringComparison(
        challengeProviderName,
        Constants.ArkoseServiceType
      )
    ) {
      challengeManagerSubmit(
        data,
        "failed",
        challengeProviderName,
        envName,
        cid
      )
        .then((res) => {
          console.log("submit failed event succeed: ", res.data);
        })
        .catch((err) => {
          console.error("submit failed event failed: ", err);
        });
    }
  };

  if (
    MiscUtil.caseInsensitiveStringComparison(
      challengeProviderName,
      Constants.ArkoseServiceType
    )
  ) {
    const challengeSessionId = MiscUtil.getQueryStringValue(
      Constants.challengeSessionIdParamName
    );
    const challengeId = MiscUtil.getQueryStringValue(
      Constants.challengeIdParamName
    );
    const publicKey = MiscUtil.getQueryStringValue(
      Constants.publicKeyParamName
    );
    const companyName = MiscUtil.getQueryStringValue(
      Constants.companyNameParamName
    );
    // replace all spaces with "+", somehow URLSearchParams() does not preserve plus signs https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#preserving_plus_signs
    const encryptedBlob = MiscUtil.getQueryStringValue(
      Constants.encryptedBlobParamName
    ).split(" ").join("+");

    // return error if any of the params is a empty string
    if (
      MiscUtil.isNullOrUndefinedOrEmptyString(challengeSessionId) ||
      MiscUtil.isNullOrUndefinedOrEmptyString(challengeId) ||
      MiscUtil.isNullOrUndefinedOrEmptyString(publicKey) ||
      MiscUtil.isNullOrUndefinedOrEmptyString(companyName) ||
      MiscUtil.isNullOrUndefinedOrEmptyString(encryptedBlob)
    ) {
      // TODO: what should happen here? should I terminate the program or retry?
      console.error("Required param is missing for Arkose adaptor");
      return (
        <div>Parameter parsing error, unable to load Arkose challenge</div>
      );
    }

    return (
      <div>
        {loading && <Spinner className={theme === 'dark' ? 'loadingdark' : 'loading'} size={SpinnerSize.large} />}
        <ArkoseAdaptor
          key={theme}
          challengeSessionId={challengeSessionId}
          challengeId={challengeId}
          publicKey={publicKey}
          companyName={companyName}
          encryptedBlob={encryptedBlob}
          onCompleted={onCompleted}
          onFailed={onFailed}
          theme={theme}
          loading={setLoading}
        />
      </div>
    );
  } else {
    return <div>Unable to load challenge</div>;
  }
};
