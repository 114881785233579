import { useEffect } from "react";
import { BaseAdaptor, IAdaptor } from "./baseAdaptor";

export interface ArkoseAdaptorProps {
  challengeSessionId: string;
  challengeId: string;
  publicKey: string;
  companyName: string;
  encryptedBlob: string;
  theme: string;
  loading: (laoding: boolean) => void;
}

export class ArkoseAdaptor extends BaseAdaptor<ArkoseAdaptorProps> {
  private _challengeSessionId: string;
  private _publicKey: string;
  private _companyName: string;
  private _encryptedBlob: string;
  private _theme: string;

  constructor(props: ArkoseAdaptorProps & IAdaptor) {
    super(props);

    this._challengeSessionId = this.props.challengeSessionId;
    this._publicKey = this.props.publicKey;
    this._companyName = this.props.companyName;
    this._encryptedBlob = this.props.encryptedBlob;
    this._theme = this.props.theme;
  }

  onCompleted(data: any): void {
    this.props.onCompleted({
      token: data.token,
      sessionId: this._challengeSessionId,
      encryptedBlob: this._encryptedBlob,
    });
  }

  onFailed(data: any): void {
    this.props.onFailed({
      token: data.token,
      sessionId: this._challengeSessionId,
      encryptedBlob: this._encryptedBlob,
    });
  }

  public onInit = (): JSX.Element => {
    const ArkoseLabs = () => {
      const createArkoseScript = () => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `https://${this._companyName}-api.arkoselabs.com/v2/${this._publicKey}/api.js`;
        script.setAttribute("data-callback", "setupEnforcement");
        script.async = true;
        script.defer = true;
        script.id = "arkose-script";

        document.head.append(script);

        return () => {
          const object = document.getElementById("arkose-script");
          if (object) {
            object.remove();
          }
        };
      };

      // We only want to have the API script tag created once
      useEffect(() => {
        return createArkoseScript();
        // eslint-disable-next-line
      }, []);

      return <div id="arkose-ec"></div>;
    };

    // Create the setup function that the Arkose Labs API will use to configure it's use
    // and the callbacks that it will trigger
    const setupEnforcement = (enforcementObject: any) => {
      enforcementObject.setConfig({
        data: {
          blob: this._encryptedBlob,
        },
        selector: "#arkose-ec",
        mode: "inline",
        onFailed: (response: any) => {
          this.onFailed(response);

          // reset Arkose EC after failed event is logged in CMS
          enforcementObject.reset();
        },
        onCompleted: (response: any) => {
          this.onCompleted(response);
        },
        onShow: () => {
          this.props.loading(false);
        },
        styleTheme: this._theme,
        accessibilitySettings: {
          grabFocusToInline: true,
        },
        onShown: (response: any) => {
          const ec = document.getElementsByTagName("iframe")[0];
          if (ec) {
            ec.focus();
          }
        },
      });
    };

    // Make the setup function a global variable so that the Arkose Labs API can run it
    // once the API has loaded. The name of this variable MUST match the name of the setup
    // function defined in the ArkoseLabs component 'data-callback' attribute
    (window as any).setupEnforcement = setupEnforcement;

    return (
      <div className="container">
        <div className="row justify-content-center">
          <ArkoseLabs />
        </div>
      </div>
    );
  };

  render() {
    return (
      <BaseAdaptor
        onCompleted={this.onCompleted}
        onFailed={this.onFailed}
        onInit={this.onInit}
      />
    );
  }
}
